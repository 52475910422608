// Libraries
import React from 'react';

// Supermove
import {Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigationDOM, useQuery, useToast} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import CreateTrainingProjectDrawer from 'modules/Organization/Settings/TrainingProject/components/CreateTrainingProjectDrawer';

const View = Styled.View``;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  margin-bottom: 10px;
  color: ${colors.gray.primary};
`;

const TrainingJobSettings = () => {
  const {navigator} = useNavigationDOM();
  const createTrainingProjectDrawer = useDrawer({name: 'Create Training Project Drawer'});
  const createTrainingProjectSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Training project created',
    actionText: 'View Project',
    handleAction: ({projectUuid}) => {
      // ensures back button on project page goes to calendar and not settings
      navigator.replace('/');
      navigator.push(`/projects/${projectUuid}`);
    },
    duration: Infinity,
  });
  const {data} = useQuery(TrainingJobSettings.query, {
    fetchPolicy: 'cache-and-network',
  });
  const isDisabled = data?.viewer.organizationForCreateProject.moveProjectTypes.length === 0;

  return (
    <React.Fragment>
      <Title>Create Training Project</Title>
      <Subtitle>Create a training project to practice using Supermove.</Subtitle>
      <View style={{flexDirection: 'row'}}>
        <TextTooltip
          isEnabledMobileToast={false}
          text={isDisabled ? 'Create a project type to enable training projects.' : ''}
        >
          <View>
            <Button
              isResponsive
              iconLeft={Icon.Plus}
              text={'Create Training Project'}
              onPress={createTrainingProjectDrawer.handleOpen}
              color={colors.purple.hover}
              isDisabled={isDisabled}
            />
          </View>
        </TextTooltip>
      </View>
      <CreateTrainingProjectDrawer
        key={createTrainingProjectDrawer.key}
        isOpen={createTrainingProjectDrawer.isOpen}
        onSuccess={({projectUuid}: any) => {
          createTrainingProjectDrawer.handleClose();
          createTrainingProjectSuccessToast.handleToast({actionPayload: {projectUuid}});
        }}
        handleClose={createTrainingProjectDrawer.handleClose}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TrainingJobSettings.query = gql`
  query TrainingJobSettings {
    ${gql.query}
    viewer {
      id
      organizationForCreateProject {
        id
        moveProjectTypes: projectTypesForCategory(category: "MOVE") {
          id
        }
      }
    }
  }
`;

export default TrainingJobSettings;
