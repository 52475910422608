// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import SidebarPage from 'modules/App/components/SidebarPage';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import TrainingJobSettings from 'modules/Organization/Settings/components/TrainingJobSettings';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View<{responsive: ResponsiveType}>`
  width: ${({responsive}) => (responsive.mobile ? '100%' : 'fit-content')};
  padding-horizontal: ${({responsive}) => (responsive.mobile ? 12 : 24)}px;
`;

const Section = Styled.View<{sectionIndex: number}>`
  margin-bottom: 30px;
  z-index: ${(props) => 100 - props.sectionIndex};
`;

const SectionTitle = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const SectionSubtitle = Styled.H6`
  margin-bottom: 10px;
  color: ${colors.gray.primary};
`;

const SettingsItem = ({
  sectionIndex,
  title,
  subtitle,
  buttonText,
  route,
}: {
  sectionIndex: number;
  title: string;
  subtitle: string;
  buttonText: string;
  route: string;
}) => {
  const {navigator} = useNavigationDOM();
  return (
    <Section sectionIndex={sectionIndex}>
      <SectionTitle>{title}</SectionTitle>
      <SectionSubtitle>{subtitle}</SectionSubtitle>
      <Button isResponsive text={buttonText} onPress={() => navigator.push(route)} />
    </Section>
  );
};

interface SettingsItemProps {
  sectionIndex: number;
}

const UsersSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Staff'}
    subtitle={'Settings related to your office staff and movers.'}
    buttonText={'View/Edit Staff'}
    route={'/settings/users/staff'}
  />
);

const CommunicationSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Communication'}
    subtitle={'Manage your SMS and email settings.'}
    buttonText={'View/Edit Communication Setting'}
    route={'/settings/communication/general'}
  />
);

const ProjectSettings = ({
  sectionIndex,
  isViewOnly,
}: SettingsItemProps & {isViewOnly?: boolean}) => {
  return (
    <SettingsItem
      sectionIndex={sectionIndex}
      title={'Projects'}
      subtitle={`View${
        isViewOnly ? '' : ' and edit'
      } your project settings, including project and job types, billing libraries, and tags.`}
      buttonText={`View${isViewOnly ? '' : '/Edit'} Project`}
      route={'/settings/projects/project-types'}
    />
  );
};

const BillingLibrariesSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Billing'}
    subtitle={'View and edit your billing.'}
    buttonText={'View/Edit Billing Settings'}
    route={'/settings/billing/billing-libraries'}
  />
);

const DocumentsSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Documents'}
    subtitle={'View and edit your document library and document flows.'}
    buttonText={'View/Edit Documents'}
    route={'/settings/documents/document-templates'}
  />
);

const WorkflowSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Automations'}
    subtitle={'View and edit your automations.'}
    buttonText={'View/Edit Automations'}
    route={'/settings/automations'}
  />
);

const TasksSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Tasks'}
    subtitle={'Manage your task templates.'}
    buttonText={'Manage Tasks'}
    route={'/settings/tasks'}
  />
);

const CompanySettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Company Settings'}
    subtitle={'View and edit your company settings.'}
    buttonText={'View/Edit Company Settings'}
    route={'/settings/company/business-info'}
  />
);

const MovesSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Moves'}
    subtitle={'Manage your moves (calendars, trucks & slots, locations, equipment & materials).'}
    buttonText={'Manage Moves'}
    route={'/settings/moves/calendars'}
  />
);

const InventorySettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Inventory'}
    subtitle={'Manage your inventory.'}
    buttonText={'Manage Inventory'}
    route={'/settings/inventory/general'}
  />
);

const JobSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Import Jobs'}
    subtitle={'Use a CSV file to import past jobs.'}
    buttonText={'Import Jobs'}
    route={'/settings/jobs'}
  />
);

const IntegrationSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'API Integrations'}
    subtitle={'View and edit API integrations.'}
    buttonText={'View/Edit Integrations'}
    route={'/settings/integrations/webhooks'}
  />
);

const StorageSettings = ({sectionIndex}: SettingsItemProps) => (
  <SettingsItem
    sectionIndex={sectionIndex}
    title={'Storage'}
    subtitle={'Manage storage settings.'}
    buttonText={'Manage Storage'}
    route={'/settings/storage'}
  />
);

const SettingsBlock = ({
  index,
  block,
  viewer,
}: {
  index: number;
  block: {kind: string};
  viewer: UserModel;
}) => {
  const isDependentBranch = !OrganizationKind.getIsIndependent(viewer.viewingOrganization.kind);
  switch (block.kind) {
    case 'MANAGER_SETTINGS_BILLING':
      if (isDependentBranch) {
        return null;
      }
      return (
        <React.Fragment>
          <ProjectSettings sectionIndex={index} />
          <BillingLibrariesSettings sectionIndex={index} />
        </React.Fragment>
      );
    case 'MANAGER_SETTINGS_COMPANY_SETTINGS':
      return <CompanySettings sectionIndex={index} />;
    case 'MANAGER_SETTINGS_CREATE_TRAINING_JOB':
      if (!viewer.viewingOrganization.canCreateJobs) {
        return null;
      }
      return (
        <Section sectionIndex={index}>
          <TrainingJobSettings />
        </Section>
      );
    case 'MANAGER_DOCUMENT_TEMPLATE_SETTINGS':
      if (isDependentBranch) {
        return null;
      }
      return <DocumentsSettings sectionIndex={index} />;
    case 'MANAGER_WORKFLOW_SETTINGS_BLOCK':
      return <WorkflowSettings sectionIndex={index} />;
    case 'MANAGER_SETTINGS_TASKS':
      return <TasksSettings sectionIndex={index} />;
    case 'MANAGER_SETTINGS_COMMUNICATION':
      return <CommunicationSettings sectionIndex={index} />;
    case 'MANAGER_SETTINGS_INVENTORY':
      if (isDependentBranch) {
        return null;
      }
      return <InventorySettings sectionIndex={index} />;
    case 'MANAGER_SETTINGS_MOVES':
      return <MovesSettings sectionIndex={index} />;
    case 'MANAGER_SETTINGS_JOBS':
      return <JobSettings sectionIndex={index} />;
    case 'MANAGER_SETTINGS_STAFF':
      return <UsersSettings sectionIndex={index} />;
    case 'MANAGER_SETTINGS_STORAGE':
      if (!viewer.viewingOrganization.settings.isStorageEnabled) {
        return null;
      }
      return <StorageSettings sectionIndex={index} />;
    case 'MANAGER_SETTINGS_INTEGRATIONS':
      return <IntegrationSettings sectionIndex={index} />;
    default:
      return null;
  }
};

const OrganizationSettingsPageContent = ({viewer}: {viewer: UserModel}) => {
  const responsive = useResponsive();

  return (
    <Container responsive={responsive}>
      <Space height={20} />
      {viewer.viewingOrganization.managerSettingsPageBlock.blocks.map(
        (block: {kind: string}, index: number) => (
          <SettingsBlock key={index} index={index} block={block} viewer={viewer} />
        ),
      )}
      <Space height={80} />
    </Container>
  );
};

const OrganizationSettingsPage = () => (
  <SidebarPage selected={'settings'} query={OrganizationSettingsPage.query}>
    {({data}: {data: {viewer: UserModel}}) => (
      <Wrapper>
        <StandardOfficeHeader title={'Settings'} />
        <ScrollView style={{flex: 1}}>
          <OrganizationSettingsPageContent viewer={data.viewer} />
        </ScrollView>
      </Wrapper>
    )}
  </SidebarPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsPage.query = gql`
  query OrganizationSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        canCreateJobs
        kind
        settings {
          id
          isStorageEnabled
        }
        managerSettingsPageBlock: block(kind: "MANAGER_SETTINGS_PAGE") {
          blocks {
            kind
          }
        }
      }
    }
  }
`;

export default OrganizationSettingsPage;
