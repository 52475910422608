// Library
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {
  ResponsiveType,
  usePagination,
  TabsType,
  useDebouncedCallback,
  useEffect,
} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Line from '@shared/design/components/Line';
import CommunicationInboxProjectList from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxProjectList';
import CommunicationInboxProjectListSearchFilter from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxProjectListSearchFilter';
import CommunicationInboxProjectStatusTabs from 'modules/Communication/CommunicationInbox/ConversationList/components/CommunicationInboxProjectStatusTabs';
import {CommunicationInboxProjectListUrlFilterType} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';
import {useCommunicationNewSseSubscription} from 'modules/Communication/hooks/useCommunicationNewSseSubscription';

interface CommunicationInboxProjectsProps {
  responsive: ResponsiveType;
  urlFilters: CommunicationInboxProjectListUrlFilterType;
  organization: OrganizationModel;
  viewerId: string;
  mobilePaneTabs?: TabsType;
}

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.white}
`;

const CommunicationInboxProjects = ({
  responsive,
  urlFilters,
  organization,
  viewerId,
  mobilePaneTabs,
}: CommunicationInboxProjectsProps) => {
  const currentPage = _.toNumber(urlFilters.get('projectsPage')) || 1;
  const {loading, data, error, refetch} = useQuery(CommunicationInboxProjects.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: currentPage,
        resultsPerPage: 20,
      },
      conversationStatus: urlFilters.get('conversationStatus'),
      searchQuery: urlFilters.get('projectSearchQuery'),
      fromDate: urlFilters.get('fromDate'),
      toDate: urlFilters.get('toDate'),
      projectTypeIds: urlFilters.get('projectTypeIds'),
      projectTagIds: urlFilters.get('projectTagIds'),
      userId: urlFilters.get('users') === 'all' ? undefined : parseInt(viewerId, 10),
    },
  });
  const debouncedRefetch = useDebouncedCallback(refetch, 1000);
  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      urlFilters.handleUpdate({projectsPage: page});
    },
  });

  const {latestEvent, subscribeToOrganization, subscribeToUser, unsubscribe} =
    useCommunicationNewSseSubscription();

  useEffect(() => {
    if (urlFilters.get('users') === 'all') {
      subscribeToOrganization(organization.id);
    } else {
      subscribeToUser(viewerId);
    }

    return () => {
      unsubscribe();
    };
  }, [urlFilters.get('users')]);

  useEffect(() => {
    if (latestEvent && organization.features.isEnabledRealTimeMessages) {
      debouncedRefetch();
    }
  }, [latestEvent]);

  return (
    <Container>
      <Space height={16} />
      <CommunicationInboxProjectListSearchFilter
        urlFilters={urlFilters}
        responsive={responsive}
        organization={organization}
      />
      <Space height={16} />
      <CommunicationInboxProjectStatusTabs
        urlFilters={urlFilters}
        conversationCount={data?.allConversationsCount}
        openCount={data?.followUpConversationsCount}
      />
      <Line />
      <CommunicationInboxProjectList
        responsive={responsive}
        urlFilters={urlFilters}
        loading={loading}
        conversations={data?.paginatedList?.conversation ?? []}
        pagination={pagination}
        error={error}
        mobilePaneTabs={mobilePaneTabs}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxProjects.query = gql`
  ${usePagination.fragment}
  ${CommunicationInboxProjectList.fragment}

  query CommunicationInboxProjectList(
    $pagination: PaginationInput!,
    $conversationStatus: String,
    $fromDate: String,
    $toDate: String,
    $searchQuery: String,
    $projectTypeIds: [Int],
    $projectTagIds: [Int],
    $userId: Int
  ) {
    ${gql.query}
    paginatedList: filteredConversationsPaginatedList(
      pagination: $pagination,
      conversationStatus: $conversationStatus,
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      projectTypeIds: $projectTypeIds,
      projectTagIds: $projectTagIds,
      userId: $userId
    ) {
      conversation: results {
        id
        ...CommunicationInboxProjectList
      }
      paginationMetadata {
        ...usePagination
      }
    }

    allConversationsCount: filteredConversationsCount(
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      projectTypeIds: $projectTypeIds,
      projectTagIds: $projectTagIds,
      userId: $userId
    )

    followUpConversationsCount: filteredConversationsCount(
      conversationStatus: "OPEN",
      fromDate: $fromDate,
      toDate: $toDate,
      searchQuery: $searchQuery,
      projectTypeIds: $projectTypeIds,
      projectTagIds: $projectTagIds,
      userId: $userId
    )
  }
`;

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxProjects.fragment = gql`
  ${CommunicationInboxProjectListSearchFilter.fragment}

  fragment CommunicationInboxProjects on Organization {
    id
    features {
      isEnabledRealTimeMessages: isEnabled(feature: "REAL_TIME_MESSAGES")
    }
    ...CommunicationInboxProjectListSearchFilter
  }
`;

export default CommunicationInboxProjects;
