// Supermove
import {useState} from '@supermove/hooks';

// App
import {useSSE} from '@shared/modules/SSE/hooks/useSSE';
import {
  ChannelType,
  SSEChannelSubscriptionRequest,
  SSEEventType,
} from '@shared/modules/SSE/interfaces';

export const useCommunicationNewSseSubscription = () => {
  const [subscriptions, setSubscriptions] = useState<SSEChannelSubscriptionRequest[]>([]);
  const {
    subscribe,
    unsubscribe: sseUnsubscribe,
    latestEvent,
  } = useSSE([SSEEventType.COMMUNICATION_NEW]);

  const subscribeToProject = (projectId: string) => {
    const subscription = {
      channelId: projectId,
      channelType: ChannelType.PROJECT,
    };
    setSubscriptions([subscription]);
    subscribe([subscription]);
  };

  const subscribeToOrganization = (organizationId: string) => {
    const subscription = {
      channelId: organizationId,
      channelType: ChannelType.ORGANIZATION,
    };
    setSubscriptions([subscription]);
    subscribe([subscription]);
  };

  const subscribeToUser = (userId: string) => {
    const subscription = {
      channelId: userId,
      channelType: ChannelType.USER,
    };
    setSubscriptions([subscription]);
    subscribe([subscription]);
  };

  const unsubscribe = () => {
    sseUnsubscribe(subscriptions);
    setSubscriptions([]);
  };

  return {latestEvent, subscribeToProject, subscribeToOrganization, subscribeToUser, unsubscribe};
};
