// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {JobModel, UserModel} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import {UpdateUserDayByDateMutation} from 'modules/Employee/User/Schedule/components';

const Container = Styled.View`
  flex-direction: row;
`;

const JobCellContainer = Styled.View<{
  color: string;
  opacity?: number;
}>`
  justify-content: flex-start;
  width: 110px;
  height: 75px;
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${({color}) => color};
  opacity: ${({opacity}) => opacity || 1.0};
  overflow: hidden;
`;

const Touchable = Styled.Touchable`
`;

const Job = Styled.View`
  width: 100%;
  padding-horizontal: 3px;
  padding-vertical: 2px;
  margin-bottom: 3px;
  background-color: ${colors.gray.primary};
  border-radius: 3px;
`;

const JobText = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.white};
`;

const IndicatorWrapper = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Indicator = Styled.Loading`
`;

const sortJobs = ({jobs}: {jobs: JobModel[]}) => {
  return _.sortBy(jobs, ['startTime1', 'startTime2']);
};

const getColorByUserDay = ({userDay}: any) => {
  const {scheduleStatus} = userDay || {};
  switch (scheduleStatus) {
    case 'AVAILABLE':
      return colors.green.status;
    case 'NOT_AVAILABLE':
      return colors.Pink600;
    default:
      return colors.gray.border;
  }
};

const getCellOpacity = ({date}: any) => {
  if (!date) {
    return 1.0;
  }
  return Datetime.isPast(date) ? 0.5 : 1.0;
};

const getToggledScheduleStatus = ({scheduleDay}: any) => {
  if (!scheduleDay.userDay) {
    return 'NOT_AVAILABLE';
  }
  switch (scheduleDay.userDay.scheduleStatus) {
    case 'AVAILABLE':
      return 'NOT_AVAILABLE';
    case 'NOT_AVAILABLE':
    default:
      return 'AVAILABLE';
  }
};

const JobCell = ({
  handlePress,
  scheduleDay,
  loading,
}: {
  handlePress: () => void;
  scheduleDay: any;
  loading: boolean;
}) => {
  const {navigator} = useNavigationDOM();
  return (
    <Touchable onPress={handlePress}>
      <JobCellContainer
        color={getColorByUserDay({userDay: scheduleDay.userDay})}
        opacity={getCellOpacity({date: scheduleDay.date})}
      >
        {loading ? (
          <IndicatorWrapper>
            <Indicator size={'small'} color={colors.gray.border} />
          </IndicatorWrapper>
        ) : (
          <React.Fragment>
            {sortJobs({jobs: scheduleDay.jobs}).map((job) => (
              <Touchable key={`${job.id}`} onPress={() => navigator.push(`/jobs/${job.uuid}`)}>
                <Job>
                  <JobText numberOfLines={1}>
                    {`#${job.number} - ${Datetime.convertToDisplayTime(job.startTime1)}`}
                  </JobText>
                </Job>
              </Touchable>
            ))}
          </React.Fragment>
        )}
      </JobCellContainer>
    </Touchable>
  );
};

const ScheduleDayItem = ({scheduleDay, user}: {scheduleDay: any; user: UserModel}) => {
  return scheduleDay.userDay ? (
    <UpdateUserDayByDateMutation
      date={scheduleDay.date}
      scheduleStatus={getToggledScheduleStatus({scheduleDay})}
      userId={user.id}
    >
      {({loading, handleSubmit}: {loading: boolean; handleSubmit: () => void}) => (
        <JobCell handlePress={handleSubmit} scheduleDay={scheduleDay} loading={loading} />
      )}
    </UpdateUserDayByDateMutation>
  ) : (
    <UpdateUserDayByDateMutation
      date={scheduleDay.date}
      scheduleStatus={'AVAILABLE'}
      userId={user.id}
      refetchQueries={['EmployeeSchedules']}
    >
      {({loading, handleSubmit}: {loading: boolean; handleSubmit: () => void}) => (
        <JobCell handlePress={handleSubmit} scheduleDay={scheduleDay} loading={loading} />
      )}
    </UpdateUserDayByDateMutation>
  );
};

const CrewScheduleItem = ({scheduleDays, user}: {scheduleDays: any; user: UserModel}) => (
  <Container>
    {scheduleDays.map((scheduleDay: any, index: any) => (
      <ScheduleDayItem key={index} scheduleDay={scheduleDay} user={user} />
    ))}
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewScheduleItem.fragment = gql`
  fragment CrewScheduleItem on EmployeeScheduleDay {
    date
    day {
      id
      value
    }
    userDay {
      id
      userId
      dayId
      scheduleStatus
    }
    jobs {
      id
      uuid
      number
      startTime1
    }
  }
`;

export default CrewScheduleItem;
