// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {SupermoveNavigatorType} from '@supermove/navigation/src/NavigationTypes';
import {colors} from '@supermove/styles';

const ButtonsRow = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-left: 8px;
`;

const Button = Styled.ButtonV2`
  padding: 5px;
`;

const ClientProjectActions = ({
  navigator,
  projectUuid,
}: {
  navigator: SupermoveNavigatorType;
  projectUuid: string;
}) => {
  const handlePress = () => {
    navigator.push(`/projects/${projectUuid}/email/compose`);
  };
  return (
    <ButtonsRow>
      <Button onPress={handlePress}>
        <Icon source={Icon.Envelope} color={colors.gray.secondary} size={16} />
      </Button>
    </ButtonsRow>
  );
};

export default ClientProjectActions;
