// Libraries
import React from 'react';

// Supermove
import {Drawer as SupermoveDrawer, Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Drawer from '@shared/design/components/Drawer';
import useUpdateAllReadAtInAppNotificationsMutation from '@shared/modules/InAppNotification/hooks/useUpdateAllReadAtInAppNotificationsMutation';
import MobileHeader from 'modules/App/Global/components/MobileHeader';
import Line from 'modules/App/components/Line';
import NotificationPopover from 'modules/App/components/OfficeHeader/NotificationPopover';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import useAppContext from 'modules/App/context/useAppContext';

const Container = Styled.View`
`;

const UnreadCountContainer = Styled.View`
  padding-horizontal: 4px;
  padding-vertical: 2px;
  border-radius: 4px;
  background-color: ${colors.red.warning};
`;

const UnreadCountText = Styled.Text`
  ${Typography.MicroLabel}
  color: ${colors.white};
`;

const SheetContainer = Styled.View`
  padding-vertical: 16px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background-color: ${colors.white};
  align-items: center;
`;

const SheetActionButton = Styled.ButtonV2`
  height: 32px;
  justify-content: center;
`;

const SheetText = Styled.Text`
  ${Typography.Body}
  padding-vertical: 8px;
  color: ${colors.blue.interactive};
`;

const ActivityIndicator = Styled.Loading`
`;

const LoadingCard = () => {
  return (
    <Container style={{paddingHorizontal: 16}}>
      <Space height={16} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={160} />
      <Space height={4} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={280} />
      <Space height={8} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={120} />
      <Space height={16} />
    </Container>
  );
};

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <LoadingCard />
      <Line />
      <LoadingCard />
      <Line />
      <LoadingCard />
    </React.Fragment>
  );
};

const SheetAction = ({text, handleAction, isSubmitting}: any) => {
  return (
    <SheetActionButton onPress={handleAction}>
      {isSubmitting ? (
        <ActivityIndicator size={'small'} color={colors.gray.tertiary} />
      ) : (
        <SheetText>{text}</SheetText>
      )}
    </SheetActionButton>
  );
};

const NotificationActionsSheet = ({refetch, handleClose, notificationsOptionsSheet}: any) => {
  const {viewer} = useAppContext();
  const {navigator} = useNavigationDOM();
  const {submitting, handleSubmit} = useUpdateAllReadAtInAppNotificationsMutation({
    receiverId: viewer?.id,
    onSuccess: () => {
      notificationsOptionsSheet.handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  // TODO(dan) Switch SupermoveDrawer into Sheet once it's added to design system
  return (
    <SupermoveDrawer
      isOpen={notificationsOptionsSheet.isOpen}
      onClose={notificationsOptionsSheet.handleClose}
      position={SupermoveDrawer.POSITIONS.BOTTOM}
    >
      <SheetContainer>
        {viewer && (
          <SheetAction
            text={'Mark All Read'}
            handleAction={handleSubmit}
            isSubmiting={submitting}
          />
        )}
        <SheetAction
          text={'Notification Settings'}
          handleAction={() => {
            navigator.push('/account/profile/notifications');
            notificationsOptionsSheet.handleClose();
            handleClose();
          }}
        />
      </SheetContainer>
    </SupermoveDrawer>
  );
};

const MobileNotificationsDrawerHeader = ({handleClose, refetch, loading}: any) => {
  const {viewer} = useAppContext();
  const notificationsOptionsSheet = useDrawer({name: 'Notifications Options Drawer'});

  return (
    <MobileHeader handleClose={handleClose} title={'Notifications'}>
      {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
      {viewer?.unreadNotificationsCount > 0 && (
        <React.Fragment>
          <Space width={8} />
          <UnreadCountContainer>
            {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
            <UnreadCountText>{viewer.unreadNotificationsCount}</UnreadCountText>
          </UnreadCountContainer>
        </React.Fragment>
      )}
      <Space style={{flex: 1}} />
      <TertiaryButton
        style={{paddingHorizontal: 16, paddingVertical: 4}}
        onPress={notificationsOptionsSheet.handleOpen}
        isDisabled={loading}
      >
        <Icon source={Icon.EllipsisV} color={colors.white} size={14} />
      </TertiaryButton>
      <Space width={4} />
      <NotificationActionsSheet
        refetch={refetch}
        handleClose={handleClose}
        notificationsOptionsSheet={notificationsOptionsSheet}
      />
    </MobileHeader>
  );
};

const MobileNotificationsDrawer = ({isOpen, handleClose}: any) => {
  const {loading, data, refetch, fetchMore} = useQuery(MobileNotificationsDrawer.query, {
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: 1,
        resultsPerPage: NotificationPopover.RESULTS_PER_PAGE,
      },
    },
  });

  return (
    <Drawer isOpen={isOpen} handleClose={handleClose} shouldCloseOnClickOutside={false}>
      <MobileNotificationsDrawerHeader
        handleClose={handleClose}
        refetch={refetch}
        loading={loading}
      />
      <Loading loading={!data} as={LoadingComponent}>
        {() => {
          return (
            <NotificationPopover.Content
              inAppNotifications={data.viewer.paginatedList.inAppNotifications}
              receiverId={data.viewer.id}
              refetch={refetch}
              onPressNotification={handleClose}
              refetchCount={refetch}
              fetchMore={fetchMore}
              loading={loading}
              paginationMetadata={data.viewer.paginatedList.paginationMetadata}
            />
          );
        }}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MobileNotificationsDrawer.query = gql`
  ${(NotificationPopover.Content as any).fragment}

  query MobileNotificationsDrawer(
    $pagination: PaginationInput!
  ) {
    ${gql.query}
    viewer {
      id
      paginatedList: inAppNotificationsPaginatedList(pagination: $pagination) {
        inAppNotifications: results {
          id
          ...NotificationPopover_Content
        }
        paginationMetadata {
          currentPage
          hasNext
        }
      }
    }
  }
`;

export default MobileNotificationsDrawer;
