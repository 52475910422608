// Libraries
import _ from 'lodash';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization, UserModel} from '@supermove/models';

// App
import {SSEEventType, ChannelType} from '@shared/modules/SSE/interfaces';
import CalendarKind from '@shared/modules/Settings/enums/CalendarKind';
import UserRole from '@shared/modules/User/enums/UserRole';

const getCanViewJobEstimateCalendar = ({viewer}: {viewer: UserModel}) => {
  return _.get(viewer, 'viewingOrganization.features.isEnabledJobEstimateCalendar', false);
};

const getCanViewDispatch = ({viewer}: {viewer: UserModel}) => {
  return UserRole.hasManagerPermissions(viewer.role);
};

const getCanViewLive = ({viewer}: {viewer: UserModel}) => {
  return UserRole.hasManagerPermissions(viewer.role);
};

const getCanViewStorage = ({viewer}: {viewer: UserModel}) => {
  return _.get(viewer, 'viewingOrganization.settings.isStorageEnabled', false);
};

const getCanViewAccounting = ({viewer}: {viewer: UserModel}) => {
  return UserRole.hasAdminPermissions(viewer.role);
};

const getCanViewPayments = ({viewer}: {viewer: UserModel}) => {
  return (
    UserRole.hasAdminPermissions(viewer.role) &&
    Organization.getIsEnabledPayengine(viewer.viewingOrganization)
  );
};

const getAccountingUrl = ({viewer}: {viewer: UserModel}) => {
  if (getCanViewAccountingInvoices({viewer})) {
    return '/accounting/invoices/move';
  } else if (getCanViewAccountingReports({viewer})) {
    if (getCanViewPayrollReports({viewer})) {
      return '/accounting/employee-payroll';
    } else if (getCanViewSalesPayroll({viewer})) {
      return '/accounting/sales-payroll';
    } else if (getCanViewCrewPayroll({viewer})) {
      return '/accounting/crew-payroll';
    }
  }
};

const getCanViewAccountingInvoices = ({viewer}: {viewer: UserModel}) => {
  return UserRole.hasAdminPermissions(viewer.role);
};

const getCanViewAccountingReports = ({viewer}: {viewer: UserModel}) => {
  return UserRole.hasAdminPermissions(viewer.role);
};

const getCanViewReports = ({viewer}: {viewer: UserModel}) => {
  return (
    UserRole.hasAdminPermissions(viewer.role) &&
    _.get(viewer, 'viewingOrganization.features.isEnabledReports', false)
  );
};

const getCanViewCrewPayroll = ({viewer}: {viewer: UserModel}) => {
  return (
    !_.get(viewer, 'viewingOrganization.features.isEnabledHideCrewPayrollTab', false) &&
    UserRole.hasAdminPermissions(viewer.role) &&
    _.get(viewer, 'viewingOrganization.features.isEnabledPayrollForCrew', false)
  );
};

const getCanViewMoverCompensationReports = ({viewer}: {viewer: UserModel}) => {
  return _.get(viewer, 'viewingOrganization.settings.isCostAndCompensationEnabled', false);
};

const getCanViewSalesPayroll = ({viewer}: {viewer: UserModel}) => {
  return (
    UserRole.hasAdminPermissions(viewer.role) &&
    _.get(viewer, 'viewingOrganization.features.isEnabledPayrollForSales', false)
  );
};

// Payroll V2
const getCanViewPayrollReports = ({viewer}: {viewer: UserModel}) => {
  return (
    UserRole.hasAdminPermissions(viewer.role) &&
    _.get(viewer, 'viewingOrganization.settings.isCostAndCompensationEnabled', false)
  );
};

const canViewOfficeConversationsTab = ({viewer}: {viewer: UserModel}) => {
  return _.get(viewer, 'viewingOrganization.features.isEnabledOfficeConversationsTab', false);
};

export const getSidebarItems = ({viewer}: {viewer: UserModel}) => {
  if (viewer.role === 'employee') {
    return [
      {
        key: 'schedule',
        to: '/1/schedule',
        name: 'Schedule',
        source: Icon.CalendarCheck,
      },
      getCanViewMoverCompensationReports({viewer}) && {
        key: 'compensation',
        to: '/1/accounting/compensation-reports',
        name: 'Payroll',
        source: Icon.HandHoldingUsd,
      },
      {
        key: 'dispatchtripview',
        to: '/1/dispatch/trips/active',
        name: 'Trips',
        source: Icon.Road,
      },
    ].filter(Boolean);
  }

  const getMovesTabUrl = () => {
    if (
      _.get(viewer, 'viewingOrganization.settings.primaryCalendar', '') === CalendarKind.CAPACITY
    ) {
      return '/moves/capacity/calendar';
    } else {
      return '/calendar';
    }
  };

  const baseTabs = [
    {
      key: 'moves',
      to: getMovesTabUrl(),
      name: 'Moves',
      source: Icon.CalendarCheck,
      sseRefetchTrigger: SSEEventType.MOVE_REQUEST_COUNT_UPDATED,
    },
    canViewOfficeConversationsTab({viewer}) && {
      key: 'conversations',
      to: '/conversations',
      name: 'Conversations',
      source: Icon.Comments,
      sseRefetchTrigger: viewer.viewingOrganization.features.isEnabledRealTimeMessages
        ? SSEEventType.COMMUNICATION_NEW
        : undefined,
      sseSubscribeChannels: viewer.viewingOrganization.features.isEnabledRealTimeMessages
        ? [
            {
              channelType: ChannelType.USER,
              channelId: viewer.id,
            },
          ]
        : [],
    },
    {
      key: 'tasks',
      to: '/tasks',
      name: 'Tasks',
      source: Icon.CheckCircle,
      sseRefetchTrigger: SSEEventType.DUE_TASK_COUNT_UPDATED,
    },
    getCanViewJobEstimateCalendar({viewer}) && {
      key: 'estimates',
      to: '/jobs/estimates/calendar',
      name: 'Surveys',
      source: Icon.ClipboardList,
    },
    getCanViewDispatch({viewer}) && {
      key: 'dispatch',
      to: '/dispatch',
      name: 'Dispatch',
      source: Icon.Truck,
    },
    getCanViewLive({viewer}) && {
      key: 'live',
      to: '/live',
      name: 'Live',
      source: Icon.MapMarkedAlt,
    },
    getCanViewStorage({viewer}) && {
      key: 'storage',
      to: '/storage/list',
      name: 'Storage',
      source: Icon.BoxOpen,
    },
    getCanViewPayments({viewer}) && {
      key: 'payments',
      to: '/payments',
      name: 'Payments',
      source: Icon.DollarSign,
    },
    getCanViewAccounting({viewer}) && {
      key: 'accounting',
      to: getAccountingUrl({viewer}),
      name: 'Accounting',
      source: Icon.Calculator,
    },
    {
      key: 'claims',
      to: '/claims',
      name: 'Claims',
      source: Icon.MoneyCheckDollarPen,
    },
    {
      key: 'customers',
      to: '/clients/customers',
      name: 'Clients',
      source: Icon.User,
    },
    getCanViewReports({viewer}) && {
      key: 'reports',
      to: '/reports',
      name: 'Reports',
      source: Icon.ChartLine,
    },
    // Allow non admin to see their compensation reports
    !getCanViewAccounting({viewer}) &&
      getCanViewMoverCompensationReports({viewer}) && {
        key: 'compensation',
        to: '/accounting/compensation-reports',
        name: 'Payroll',
        source: Icon.HandHoldingUsd,
      },
  ].filter(Boolean);

  return baseTabs;
};

getSidebarItems.fragment = gql`
  ${Organization.getIsEnabledPayengine.fragment}
  fragment User_getSidebarItems on User {
    id
    role
    viewingOrganization {
      id
      features {
        isEnabledJobEstimateCalendar: isEnabled(feature: "JOB_ESTIMATE_CALENDAR")
        isEnabledHideCrewPayrollTab: isEnabled(feature: "HIDE_CREW_PAYROLL_TAB")
        isEnabledPayrollForCrew: isEnabled(feature: "PAYROLL_FOR_CREW")
        isEnabledPayrollForSales: isEnabled(feature: "PAYROLL_FOR_SALES")
        isEnabledReports: isEnabled(feature: "REPORTS")
        isEnabledOfficeConversationsTab: isEnabled(feature: "OFFICE_CONVERSATIONS_TAB")
        isEnabledRealTimeMessages: isEnabled(feature: "REAL_TIME_MESSAGES")
      }
      settings {
        id
        isBookingCalendarEnabled
        isMovesCalendarEnabled
        isMovesTabPrimary
        isStorageEnabled
        isCostAndCompensationEnabled
        primaryCalendar
      }
      ...Organization_getIsEnabledPayengine
    }
  }
`;
