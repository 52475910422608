// Libraries
import React from 'react';

// Supermove
import {gql, useQuery} from '@supermove/graphql';
import {PopoverType, useNavigationDOM, useState, useEffect} from '@supermove/hooks';
import {
  ProjectModel,
  EmailTemplateModel,
  ProjectTypeUserFlowModel,
  UserFlowModel,
} from '@supermove/models';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';

const getActions = ({
  projectTypeUserFlows,
  sortedEmailTemplates,
  handleComposeEmail,
  onDocumentFlowSelect,
}: {
  projectTypeUserFlows: ProjectTypeUserFlowModel[];
  sortedEmailTemplates: EmailTemplateModel[];
  handleComposeEmail: (emailTemplateUuid?: string) => void;
  onDocumentFlowSelect: (userFlow: UserFlowModel) => void;
}) => {
  return [
    ...(projectTypeUserFlows.length > 0
      ? [
          {
            label: 'Document Flow',
            actions: projectTypeUserFlows.map((projectTypeUserFlow) => ({
              text: projectTypeUserFlow.userFlow.name,
              onPress: () => {
                onDocumentFlowSelect(projectTypeUserFlow.userFlow);
              },
            })),
          },
        ]
      : []),
    {
      label: 'Template',
      actions: sortedEmailTemplates.map((emailTemplate) => ({
        text: emailTemplate.name,
        onPress: () => {
          handleComposeEmail(emailTemplate.uuid);
        },
      })),
    },
    {
      label: 'Custom',
      actions: [
        {
          text: 'Compose new email',
          onPress: () => {
            handleComposeEmail();
          },
        },
      ],
    },
  ];
};

const ProjectSendEmailOptionsPopover = ({
  actionMenuPopover,
  children,
  refetch,
}: {
  actionMenuPopover: PopoverType;
  children?: React.ReactNode;
  refetch?: () => void;
}) => {
  const {params, navigator} = useNavigationDOM();

  const {data} = useQuery<{project: ProjectModel}>(ProjectSendEmailOptionsPopover.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: params.projectUuid,
    },
    skip: !actionMenuPopover.isOpen,
  });

  // This is a workaround to keep the project data when the popover is opened
  // The modal use will go away when we will migrate to new email page
  const [project, setProject] = useState(data?.project);
  useEffect(() => {
    if (data) {
      setProject(data.project);
    }
  }, [data]);

  const handleComposeEmail = (emailTemplateUuid: string = '') => {
    const queryParams = emailTemplateUuid ? `?emailTemplateUuid=${emailTemplateUuid}` : '';
    navigator.push(`/projects/${params.projectUuid}/email/compose${queryParams}`);
  };

  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={actionMenuPopover}
        actions={getActions({
          projectTypeUserFlows: project ? project.projectType.projectTypeUserFlows : [],
          sortedEmailTemplates: project ? project.organization.sortedEmailTemplates : [],
          handleComposeEmail,
          onDocumentFlowSelect: (userFlow) => {
            navigator.push(`/projects/${params.projectUuid}/document-flows/${userFlow.uuid}/new`);
          },
        })}
        width={400}
        popoverMaxHeight={556}
      >
        {children}
      </ActionMenuPopover>
    </React.Fragment>
  );
};

ProjectSendEmailOptionsPopover.query = gql`
  query ProjectSendEmailOptionsPopover($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      organization {
        id
        sortedEmailTemplates {
          id
          name
          uuid
        }
      }
      projectType {
        id
        projectTypeUserFlows {
          id
          userFlow {
            id
            uuid
            name
          }
        }
      }
    }
  }
`;

export default ProjectSendEmailOptionsPopover;
