// Libraries
import React from 'react';

// Supermove
import {Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigationDOM, usePopover} from '@supermove/hooks';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import EditProjectClientsDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectClientsDrawer';
import ProjectClientDataActions from 'modules/Project/V2/Show/components/ProjectClientDataActions';

const ClientNameActionsPopover = ({popover, client, editProjectClientsDrawer}: any) => {
  const {navigator} = useNavigationDOM();

  return (
    <Popover
      key={popover.key}
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ActionMenu
        handleClose={popover.handleClose}
        width={200}
        maxHeight={244}
        isTruncated
        actions={[
          {
            text: 'Edit client',
            onPress: editProjectClientsDrawer.handleOpen,
          },
          {
            text: 'View client profile',
            onPress: () => navigator.push(`/clients/${client.uuid}`),
          },
        ]}
      />
    </Popover>
  );
};

const ProjectClientContactName = ({project, isMicro, isBilling}: any) => {
  const client = isBilling ? project.billingClient : project.client;
  const clientNameActionsPopover = usePopover({name: 'Client Name Actions Popover'});
  const editProjectClientsDrawer = useDrawer({name: 'Edit Project Clients Drawer'});

  return (
    <React.Fragment>
      <ProjectClientDataActions
        data={client.primaryContact.fullName}
        icon={Icon.User}
        iconTooltip={'Contact Name'}
        isBilling={isBilling}
        isMicro={isMicro}
        popover={clientNameActionsPopover}
      />
      <EditProjectClientsDrawer
        key={editProjectClientsDrawer.key}
        isOpen={editProjectClientsDrawer.isOpen}
        handleClose={editProjectClientsDrawer.handleClose}
        project={project}
      />
      <ClientNameActionsPopover
        popover={clientNameActionsPopover}
        project={project}
        client={client}
        editProjectClientsDrawer={editProjectClientsDrawer}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectClientContactName.fragment = gql`
  fragment ProjectClientContactName on Project {
    id
    client {
      id
      primaryContact {
        id
        fullName
      }
    }
    billingClient {
      id
      primaryContact {
        id
        fullName
      }
    }
  }
`;

export default ProjectClientContactName;
