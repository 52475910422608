// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, usePopover} from '@supermove/hooks';
import {ProjectModel} from '@supermove/models';

// App
import EditProjectClientsDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectClientsDrawer';
import ProjectClientDataActions from 'modules/Project/V2/Show/components/ProjectClientDataActions';
import ProjectSendEmailOptionsPopover from 'modules/Project/components/ProjectSendEmailOptionsPopover';

const ProjectClientEmail = ({
  project,
  isMicro,
  isBilling,
}: {
  project: ProjectModel;
  isMicro: boolean;
  isBilling: boolean;
}) => {
  const client = isBilling ? project.billingClient : project.client;
  const editProjectClientsDrawer = useDrawer({name: 'Edit Project Clients Drawer'});
  const projectSendEmailOptionsPopover = usePopover();

  return (
    <React.Fragment>
      <ProjectClientDataActions
        data={client.primaryContact.email}
        addDataText={'Add Email'}
        onAddDataPress={editProjectClientsDrawer.handleOpen}
        icon={Icon.Envelope}
        iconTooltip={'Contact Email'}
        isBilling={isBilling}
        isMicro={isMicro}
        popover={projectSendEmailOptionsPopover}
      />
      <EditProjectClientsDrawer
        key={editProjectClientsDrawer.key}
        isOpen={editProjectClientsDrawer.isOpen}
        handleClose={editProjectClientsDrawer.handleClose}
        project={project}
      />
      <ProjectSendEmailOptionsPopover actionMenuPopover={projectSendEmailOptionsPopover} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectClientEmail.fragment = gql`
  fragment ProjectClientEmail on Project {
    id
    client {
      id
      primaryContact {
        id
        email
      }
    }
    billingClient {
      id
      primaryContact {
        id
        email
      }
    }
    organization {
      id
      sortedEmailTemplates {
        id
        name
        uuid
      }
    }
  }
`;

export default ProjectClientEmail;
