// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {TextInputRefType, usePopover, useResponsive} from '@supermove/hooks';
import {ThreadModel, UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SearchBar from '@shared/design/components/SearchBar';
import TextTooltip from '@shared/design/components/TextTooltip';
import Line from 'modules/App/components/Line';
import EmailThreadsListV2 from 'modules/Communication/Email/components/EmailThreadsListV2';
import ProjectSendEmailOptionsPopover from 'modules/Project/components/ProjectSendEmailOptionsPopover';

const EmailActionsContainer = Styled.View`
  padding: 16px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TooltipContainer = Styled.View`
`;

const SearchResultsText = Styled.Text`
  ${Typography.Responsive.Body};
`;

const SendEmailButton = ({
  isCustomerEmailProvided,
  projectSendEmailOptionsPopover,
  refetch,
}: {
  isCustomerEmailProvided: boolean;
  projectSendEmailOptionsPopover: any;
  refetch: () => void;
}) => {
  if (isCustomerEmailProvided) {
    return (
      <React.Fragment>
        <Button
          iconLeft={Icon.Plus}
          iconSize={14}
          onPress={projectSendEmailOptionsPopover.handleOpen}
          isResponsive
        />
        <ProjectSendEmailOptionsPopover
          actionMenuPopover={projectSendEmailOptionsPopover}
          refetch={refetch}
        />
      </React.Fragment>
    );
  }

  return (
    <TextTooltip isEnabledMobileToast={false} text={'Missing Email'} placement={'left'}>
      <TooltipContainer>
        <Button isDisabled iconLeft={Icon.Plus} iconSize={14} isResponsive />
      </TooltipContainer>
    </TextTooltip>
  );
};

const EmailActions = ({
  resultsCount,
  handleChangeQuery,
  searchInput,
  searchQuery,
  isLoading,
  isCustomerEmailProvided,
  refetch,
  showSendEmailButton = true,
}: {
  resultsCount: number;
  handleChangeQuery: (text?: string) => void;
  searchInput: TextInputRefType | null;
  searchQuery: string;
  isLoading: boolean;
  isCustomerEmailProvided: boolean;
  refetch: () => void;
  showSendEmailButton?: boolean;
}) => {
  const responsive = useResponsive();
  const projectSendEmailOptionsPopover = usePopover({name: 'Project Send Email Options Popover'});

  return (
    <React.Fragment>
      <EmailActionsContainer>
        <Row>
          <SearchBar
            inputRef={searchInput?.ref}
            onChangeText={(text) => handleChangeQuery(text)}
            placeholder={'Search'}
            containerStyle={{flex: 1}}
            style={{width: '100%'}}
            defaultValue={''}
            isLoading={isLoading}
            isClearable
            isResponsive
          />
          {
            <Row>
              <Space width={responsive.desktop ? 12 : 16} />
              {showSendEmailButton ? (
                <SendEmailButton
                  isCustomerEmailProvided={isCustomerEmailProvided}
                  projectSendEmailOptionsPopover={projectSendEmailOptionsPopover}
                  refetch={refetch}
                />
              ) : null}
            </Row>
          }
        </Row>
        <Space height={4} />
        {!!searchQuery && (
          <React.Fragment>
            <Space height={4} />
            <SearchResultsText responsive={responsive}>
              {pluralize('result', resultsCount, true)} found
            </SearchResultsText>
            <Space height={4} />
          </React.Fragment>
        )}
      </EmailActionsContainer>
    </React.Fragment>
  );
};

type EmailThreadsV2Props = {
  emailThreads: ThreadModel[];
  handleMissingContactInfo: () => void;
  setSelectedThread?: (thread: ThreadModel) => void;
  searchInput?: TextInputRefType | null;
  searchQuery?: string;
  handleChangeQuery?: (text?: string) => void;
  isLoading?: boolean;
  customer: UserModel;
  refetch: () => void;
  showSendEmailButton?: boolean;
};

const EmailThreadsV2 = ({
  emailThreads,
  handleMissingContactInfo,
  setSelectedThread = () => {},
  searchInput = null,
  searchQuery = '',
  handleChangeQuery = () => {},
  isLoading = false,
  customer,
  refetch,
  showSendEmailButton = true,
}: EmailThreadsV2Props) => {
  return (
    <React.Fragment>
      <EmailActions
        resultsCount={emailThreads.length}
        handleChangeQuery={handleChangeQuery}
        searchInput={searchInput}
        searchQuery={searchQuery}
        isLoading={isLoading}
        isCustomerEmailProvided={!!customer.email}
        refetch={refetch}
        showSendEmailButton={showSendEmailButton}
      />
      <Line />
      <EmailThreadsListV2
        emailThreads={emailThreads}
        customer={customer}
        handleMissingContactInfo={handleMissingContactInfo}
        setSelectedThread={setSelectedThread}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailThreadsV2.fragment = gql`
  ${EmailThreadsListV2.fragment}

  fragment EmailThreadsV2_User on User {
    id
    ...EmailThreadsListV2_User
  }

  fragment EmailThreadsV2_Thread on Thread {
    id
    ...EmailThreadsListV2_Thread
  }
`;

export default EmailThreadsV2;
