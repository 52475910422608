// Library
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {Conversation, ConversationModel, ConversationStatusModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Text from '@shared/design/components/Text';
import TextTooltip from '@shared/design/components/TextTooltip';
import ConversationForm from '@shared/modules/Communication/forms/ConversationForm';
import useUpdateConversationMutation from '@shared/modules/Communication/hooks/useUpdateConversationMutation';
import ConversationItemStatus from 'modules/Communication/CommunicationInbox/ConversationList/components/ConversationItemStatus';

const StatusFromDateActions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StatusFromDate = Styled.View`
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
`;

const IconContainer = Styled.ButtonV2`
`;

const CommunicationInboxFollowUpButton = ({conversation}: {conversation: ConversationModel}) => {
  const {form, handleSubmit} = useUpdateConversationMutation({
    conversationForm: ConversationForm.edit(conversation),
    refetchQueries: ['CommunicationInboxProjectList', 'GlobalNavigationSidebar'],
    onSuccess: () => null,
    onError: (error) => console.log({error}),
  });

  const handleChangeStatus = (newStatus: ConversationStatusModel) => {
    form.setFieldValue('conversationForm.status', newStatus);
    setImmediate(handleSubmit);
  };
  const isOpen = conversation.status === Conversation.ConversationStatus.OPEN;

  return (
    <TextTooltip text={isOpen ? 'Mark as resolved' : 'Mark as follow up'}>
      <IconContainer
        onPress={() =>
          handleChangeStatus(
            isOpen ? Conversation.ConversationStatus.CLOSED : Conversation.ConversationStatus.OPEN,
          )
        }
      >
        <Icon
          source={isOpen ? Icon.OctagonExclamation : Icon.EmptyCircleCheck}
          color={isOpen ? colors.orange.status : colors.gray.secondary}
        />
      </IconContainer>
    </TextTooltip>
  );
};

interface CommunicationInboxTopLineProps {
  responsive: ResponsiveType;
  conversation: ConversationModel;
  fromName?: string;
  sentAt?: string;
}

const CommunicationInboxTopLine = ({
  responsive,
  conversation,
  fromName,
  sentAt,
}: CommunicationInboxTopLineProps) => {
  return (
    <StatusFromDateActions>
      <StatusFromDate>
        <ConversationItemStatus responsive={responsive} conversation={conversation} />
        <Space width={4} />
        {fromName ? (
          <Text.Micro numberOfLines={1} style={{flexShrink: 1}}>
            {fromName},
          </Text.Micro>
        ) : null}
        <Space width={4} />
        <Text.Micro numberOfLines={1} style={{flexShrink: 0}}>
          {sentAt ? Datetime.convertToDisplayDatetime(sentAt) : null}
        </Text.Micro>
      </StatusFromDate>
      <Space width={8} />
      <Actions>
        <CommunicationInboxFollowUpButton conversation={conversation} />
      </Actions>
    </StatusFromDateActions>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxTopLine.fragment = gql`
  ${Conversation.getStatusInfo.fragment}
  ${ConversationForm.edit.fragment}

  fragment CommunicationInboxTopLine on Conversation {
    id
    status
    ...Conversation_getStatusInfo
    ...ConversationForm_edit
  }
`;

export default CommunicationInboxTopLine;
