// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ModalType, useHover, useNavigationDOM, useSidebar} from '@supermove/hooks';
import {Project, Location, ProjectModel} from '@supermove/models';
import {SupermoveNavigatorType} from '@supermove/navigation/src/NavigationTypes';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime, pluralize} from '@supermove/utils';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ClientProjectActions from 'modules/Client/Show/components/ClientProjectActions';
import ProjectCommentsButton from 'modules/Project/components/ProjectCommentsButton';

const TableContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  min-width: 860px;
`;

const TableHeaderRow = Styled.View`
  flex-direction: row;
  padding-vertical: 10px;
  border-width: 1px;
  border-color: ${colors.white};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
`;

const TableHeaderSubText = Styled.Text`
  ${Typography.Body4}
`;

const TableRow = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const TableRowContentContainer = Styled.ButtonV2<{isActive?: boolean}>`
  flex-direction: row;
  align-items: center;
  padding-vertical: 12px;
  border-width: 1px;
  border-color: ${({isActive}) => (isActive ? colors.hover : colors.white)};
  background-color: ${({isActive}) => (isActive ? colors.alpha(colors.hover, 0.25) : colors.white)};
`;

const TableCell = Styled.View<{flex?: number}>`
  flex: ${({flex}) => flex};
  flex-direction: row;
  align-items: center;
  padding-right: 8px;
  box-sizing: border-box;
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ProjectStatusBadge = Styled.View<{color: string}>`
  flex-direction: row;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  border-radius: 4px;
  background-color: ${({color}) => colors.alpha(color, 0.1)};
`;

const ProjectStatusText = Styled.Text<{color: string}>`
  ${Typography.Label4}
  color: ${({color}) => color};
`;

const ProjectTypeColor = Styled.View<{color: string}>`
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: ${({color}) => color};
`;

const CommentsButtonContainer = Styled.View`
  max-width: 100%;
`;

const EmptyStateContainer = Styled.View`
  align-items: center;
  justify-content: center;
  height: 120px;
  border-top-width: 1px;
  border-color: ${colors.gray.border};
`;

const EmptyStateTitle = Styled.Text`
  ${Typography.Label1}
`;

const getProjectWeight = (project: ProjectModel) => {
  return project.weight ? pluralize('lb', project.weight, true) : '--';
};

const getProjectTotalRevenue = (project: ProjectModel) => {
  return project.totalRevenue ? Currency.format({value: project.totalRevenue}) : '--';
};

const getDisplayDate = (date: string) => {
  return Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE);
};

const TableHeaderCell = ({flex, children}: {flex?: number; children: React.ReactNode}) => {
  return (
    <TableCell flex={flex}>
      <TableHeaderText>{children}</TableHeaderText>
    </TableCell>
  );
};

const ProjectStatus = ({project}: {project: ProjectModel}) => {
  if (!project.status) {
    return null;
  }

  const status = Project.getProjectStatus(project);
  const color = Project.getProjectStatusColor(project);
  return (
    <ProjectStatusBadge color={color}>
      <ProjectStatusText color={color}>{status}</ProjectStatusText>
    </ProjectStatusBadge>
  );
};

const Comments = ({
  project,
  projectCommentsSidebar,
}: {
  project: ProjectModel;
  projectCommentsSidebar: ModalType;
}) => {
  return (
    <CommentsButtonContainer>
      {!project.isStorage && (
        <Table.PreventPropagationContainer>
          <ProjectCommentsButton
            style={{paddingVertical: 8}}
            sidebar={projectCommentsSidebar}
            project={project}
          />
        </Table.PreventPropagationContainer>
      )}
    </CommentsButtonContainer>
  );
};

type ClientProjectsListColumnType = {
  flex: number;
  headerContent: () => React.ReactNode;
  cellContent: (contentProps: {
    project: ProjectModel;
    projectCommentsSidebar: ModalType;
    refetch: () => void;
    navigator: SupermoveNavigatorType;
  }) => React.ReactNode;
  isHidden?: boolean;
};

const clientProjectsListColumns: ClientProjectsListColumnType[] = [
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderCell>Status</TableHeaderCell>;
    },
    cellContent: ({project}) => {
      return <ProjectStatus project={project} />;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderCell>Project Name</TableHeaderCell>;
    },
    cellContent: ({project}) => {
      return <TableCellText numberOfLines={2}>{Project.getName(project)}</TableCellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderCell>Project Type</TableHeaderCell>;
    },
    cellContent: ({project}) => {
      return (
        <Row style={{flex: 1}}>
          <ProjectTypeColor color={project.projectType.color} />
          <Space width={6} />
          <TableCellText>{project.projectType.name}</TableCellText>
        </Row>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return (
        <Container>
          <TableHeaderText>Date </TableHeaderText>
          <TableHeaderSubText>(Start - End)</TableHeaderSubText>
        </Container>
      );
    },
    cellContent: ({project}) => {
      const {startDate, endDate} = project;
      const startText = startDate ? getDisplayDate(startDate) : 'TBD';
      const endText = endDate ? getDisplayDate(endDate) : 'TBD';
      const isOneDayProject = startDate === endDate;
      return (
        <Container>
          <TableCellText>{startText}</TableCellText>
          {startDate && !isOneDayProject && <TableCellText>{endText}</TableCellText>}
        </Container>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return (
        <Container>
          <TableHeaderText>Price</TableHeaderText>
          <TableHeaderSubText>Weight</TableHeaderSubText>
        </Container>
      );
    },
    cellContent: ({project}) => {
      const totalRevenue = getProjectTotalRevenue(project);
      const weight = getProjectWeight(project);
      return (
        <Container>
          <TableCellText>{totalRevenue}</TableCellText>
          <TableCellText style={{color: colors.gray.tertiary}}>{weight}</TableCellText>
        </Container>
      );
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return (
        <Container>
          <TableHeaderText>Origin &</TableHeaderText>
          <TableHeaderText>Destination</TableHeaderText>
        </Container>
      );
    },
    cellContent: ({project}) => {
      const start = _.get(project, 'activeJobs.0.startLocation');
      const end = _.get(project, 'activeJobs.0.endLocation');
      return (
        <Container>
          <TableCellText>{Location.getDisplayCityStateZip(start, '--')}</TableCellText>
          <TableCellText>{Location.getDisplayCityStateZip(end, '--')}</TableCellText>
        </Container>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderCell>Actions</TableHeaderCell>;
    },
    cellContent: ({navigator, project}) => {
      return <ClientProjectActions navigator={navigator} projectUuid={project.uuid} />;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderCell>Comments</TableHeaderCell>;
    },
    cellContent: ({project, projectCommentsSidebar}) => {
      return <Comments project={project} projectCommentsSidebar={projectCommentsSidebar} />;
    },
  },
];

const ProjectRow = ({project, refetch}: {project: ProjectModel; refetch: () => void}) => {
  const {navigator} = useNavigationDOM();
  const hover = useHover();
  const projectCommentsSidebar = useSidebar({
    name: 'ProjectCommentsSidebar',
    enableTracking: true,
  });

  return (
    <TableRow>
      <TableRowContentContainer
        isActive={hover.isHovered || projectCommentsSidebar.isOpen}
        ref={hover.ref}
        onPress={
          project.projectType.category === ProjectTypeCategory.STORAGE
            ? () => navigator.replace(`/storage/projects/${project.uuid}`)
            : () => navigator.push(`/projects/${project.uuid}`)
        }
      >
        <Space width={16} />
        {clientProjectsListColumns.map((column, index) => {
          if (column.isHidden) {
            return null;
          }
          return (
            <TableCell flex={column.flex} key={index}>
              {column.cellContent({project, refetch, projectCommentsSidebar, navigator})}
            </TableCell>
          );
        })}
      </TableRowContentContainer>
    </TableRow>
  );
};

const ProjectsEmptyState = ({clientName}: {clientName: string}) => {
  return (
    <EmptyStateContainer>
      <EmptyStateTitle>{`There are no projects for ${clientName}`}</EmptyStateTitle>
    </EmptyStateContainer>
  );
};

const ClientProjectsList = ({
  clientName,
  projects,
  refetch,
}: {
  clientName: string;
  projects: ProjectModel[];
  refetch: () => void;
}) => {
  return (
    <TableContainer>
      <TableHeaderRow>
        <Space width={16} />
        {clientProjectsListColumns.map((column, index) => {
          if (column.isHidden) {
            return null;
          }
          return (
            <TableCell flex={column.flex} key={index}>
              {column.headerContent()}
            </TableCell>
          );
        })}
      </TableHeaderRow>
      {_.isEmpty(projects) ? (
        <ProjectsEmptyState clientName={clientName} />
      ) : (
        projects.map((project) => {
          return <ProjectRow project={project} refetch={refetch} key={project.id} />;
        })
      )}
    </TableContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientProjectsList.fragment = gql`
  ${Project.getName.fragment}
  ${Project.getProjectStatus.fragment}
  ${Project.getProjectStatusColor.fragment}
  ${ProjectCommentsButton.fragment}
  ${Location.getDisplayCityStateZip.fragment}

  fragment ClientProjectsList on Project {
    id
    uuid
    name
    status
    startDate
    endDate
    weight
    totalRevenue
    isStorage
    projectType {
      id
      color
      name
      category
    }
    activeJobs {
      id
      startLocation {
        id
        ...Location_getDisplayCityStateZip
      }
      endLocation {
        id
        ...Location_getDisplayCityStateZip
      }
    }
    ...Project_getName
    ...Project_getProjectStatus
    ...Project_getProjectStatusColor
    ...ProjectCommentsButton
  }
`;

export default ClientProjectsList;
