// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useTabs, useUrlFilters} from '@supermove/hooks';
import {OrganizationModel, UserModel} from '@supermove/models';

// App
import MultiPaneLayout from '@shared/design/components/Layout/MultiPaneLayout';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import CommunicationInboxProjects from 'modules/Communication/CommunicationInbox/ConversationList/CommunicationInboxProjects';
import CommunicationInboxProjectConversation from 'modules/Communication/CommunicationInbox/ProjectConversation/CommunicationInboxProjectConversation';
import CommunicationInboxUserCompanySelect from 'modules/Communication/CommunicationInbox/components/CommunicationInboxUserCompanySelect';
import {
  CommunicationInboxUrlFilterType,
  CommunicationInboxUrlParams,
} from 'modules/Communication/CommunicationInbox/types/CommunicationInboxUrlFilterType';
import {allTags} from 'modules/Project/V2/Show/components/widgets/CommunicationsWidget/CommunicationWidgetTags';

const ConversationsPageMultiPaneLayout = ({
  viewer,
  organization,
  urlFilters,
}: {
  viewer: UserModel;
  organization: OrganizationModel;
  urlFilters: CommunicationInboxUrlFilterType;
}) => {
  const responsive = useResponsive();
  const mobilePaneTabs = useTabs({initialIndex: urlFilters.get('projectUuid') ? 1 : 0});
  return (
    <MultiPaneLayout
      titleText={responsive.desktop ? 'Conversations' : undefined}
      mobilePaneTabs={mobilePaneTabs}
      tabOptions={{
        tabs: [
          {
            label: 'My Conversations',
            value: 'self',
          },
          {
            label: 'Company Conversations',
            value: 'all',
          },
        ],
        activeTabIndex: urlFilters.get('users') === 'all' ? 1 : 0,
        handlePressTab: (tab: {value: 'self' | 'all'}) =>
          urlFilters.handleUpdate({users: tab.value, projectsPage: 1}),
      }}
      paneDefinitions={[
        {
          key: 'projects',
          width: 400,
          style: {flex: 1},
          bodyContentComponent: (
            <CommunicationInboxProjects
              responsive={responsive}
              urlFilters={urlFilters}
              organization={organization}
              viewerId={viewer.id}
              mobilePaneTabs={mobilePaneTabs}
            />
          ),
        },
        {
          key: 'conversation',
          style: {flex: 1},
          isFullHeight: !responsive.desktop,
          bodyContentComponent: (
            <CommunicationInboxProjectConversation
              responsive={responsive}
              urlFilters={urlFilters}
              mobilePaneTabs={mobilePaneTabs}
              isEnabledConversationsPageProjectInformation={
                organization.features.isEnabledConversationsPageProjectInformation
              }
            />
          ),
        },
      ]}
    />
  );
};

const ConversationsPage = () => {
  const {data, loading} = useQuery(ConversationsPage.query, {
    fetchPolicy: 'cache-and-network',
  });
  const urlFilters = useUrlFilters<CommunicationInboxUrlParams>({
    getRoute: () => `/conversations`,
    filterKeys: [
      'projectsPage',
      'projectUuid',
      'users',
      'projectSearchQuery',
      'conversationStatus',
      'fromDate',
      'toDate',
      'projectTypeIds',
      'projectTagIds',
      'communicationTags',
      'communicationSearchQuery',
      'textMessageTemplateUuid',
    ],
    initialFilterValues: {
      conversationStatus: 'OPEN',
      communicationTags: allTags,
    },
  });

  if (loading) {
    return (
      <SidebarPageV2 selected={'conversations'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <ConversationsPageMultiPaneLayout
      viewer={data.viewer}
      organization={data.viewer.viewingOrganization}
      urlFilters={urlFilters}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConversationsPage.query = gql`
  ${CommunicationInboxProjects.fragment}
  ${CommunicationInboxUserCompanySelect.fragment}

  query ConversationsPage {
    ${gql.query}
    viewer {
      id
      ...CommunicationInboxUserCompanySelect
      viewingOrganization {
        id
        features {
          isEnabledConversationsPageProjectInformation: isEnabled(feature: "CONVERSATIONS_PAGE_PROJECT_INFORMATION")
        }
        ...CommunicationInboxProjects
      }
    }
  }
`;

export default ConversationsPage;
